import React, { Component } from "react";
import "./style.css";
import { RightChevron } from "lib/icons";
import { TopLevelNavItem } from "components/topLevelNav/components/topLevelNavItem";
import history from "lib/history";
import onClickOutside from "react-onclickoutside";
import { cloneDeep } from "lodash";
import {
  AppChartAnalytics,
  AppChartUser,
  AppChartBar,
  AppChartLine,
  AppChartLineDown,
  AppPie,
  FolderIcon,
  LiftModelIcon,
  PromoPlanIcon,
  BaselineIcon,
  InputSourceIcon,
  ReportSvg,
  FolderMgmtIcon,
  ManageUsersIcon,
  EnvelopeIcon
} from "lib/icons";

class TopLevelParentNavItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDropDown: false
    };
    this.renderDropDown = this.renderDropDown.bind(this);
    this.wrapperRef = React.createRef();
    // this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
  }
  componentDidMount() {
    window.addEventListener("mousedown", this.handleClickOutside);
    window.addEventListener("blur", this.checkForIframeClick, false);
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("blur", this.checkForIframeClick, false);
  }

  checkForIframeClick = event => {
    if (document.activeElement.tagName === "IFRAME") {
      this.setState({
        openDropDown: false
      });
    }
  };
  handleInputChange(event, item) {
    this.props.filterTab(this.tabSearch.value, this.props.tab, this.props.selectedTab);
    // this.props.setSearchProjects(
    //   this.projectSearch.value,
    //   this.props.projects,
    //   this.props.secondary
    // );
  }
  clickHandler(clicked, index, type) {
    const tempClicked = cloneDeep(clicked);
    this.setState({ openDropDown: false });
    if (index.length === 2) {
      this.props.clickHandler(clicked, [this.props.selectedTab[0], index[0], index[1]], type);
    } else {
      if (index.length === 4 || index.length === 3) {
        this.props.clickHandler(clicked, index, type);
      } else {
        if (this.props.selectedTab[0] === -1) {
          if (clicked.tab.index === undefined) {
            let nIdx = 0;
            this.props.tabs.forEach((item, idx) => {
              if (item.workspacdId === clicked.tab.workspaceId) {
                nIdx = idx;
              }
            });
            this.props.clickHandler(clicked, [nIdx, index], type);
          } else {
            this.props.clickHandler(clicked, [clicked.tab.index[0], index], type);
          }
        } else {
          tempClicked.index = [this.props.selectedTab[0], this.props.tabs[this.props.selectedTab[0]].activeChildren.length];

          this.props.clickHandler(tempClicked, [this.props.selectedTab[0], index], type);
        }
      }
    }
  }
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ openDropDown: false });
    }
  }
  renderChildren() {
    const { tab } = this.props;

    if (tab.children !== undefined) {
      return (
        <div className="parent-top-level-nav-item">
          {tab.children.map((child, index) => {
            let activeReports = [];
            if (tab.activeChildren !== undefined && tab.activeChildren.length > 0) {
              activeReports = tab.activeChildren.map((item, idx) => {
                if (item.id === undefined) {
                  return false;
                }
                if (item.id.includes("+")) {
                  return item.id.split("+")[1];
                } else {
                  return item.id;
                }
              });
            }
            let pIndex = 0;
            if (tab.activeChildren !== undefined && tab.activeChildren.length > 0) {
              if (tab.activeChildren[0].index === undefined) {
                this.props.tabs.forEach((item, idx) => {
                  if (item.workspaceName === tab.workspaceName) {
                    pIndex = idx;
                  }
                });
              } else {
                pIndex = tab.activeChildren[0].index[0];
              }
            }
            let selected = activeReports.includes(child.id);
            if (child.id === undefined) {
              selected = false;
            }

            return (
              <TopLevelNavItem
                id={child.id}
                path={child.path}
                tab={tab}
                text={child.name}
                key={index}
                index={index}
                parentIndex={pIndex}
                type={child.type}
                fileType={child.fileType}
                selected={selected}
                selectedTab={this.props.selectedTab}
                clickHandler={this.clickHandler}
                removeHandler={this.props.removeHandler}
              />
            );
          })}
        </div>
      );
    } else {
      if (this.props.activeWS.reports === undefined) {
        if (this.props.activeWS.items.length > 0) {
          return (
            <div
              className="parent-top-level-nav-item"
              style={{
                display: "flex",
                flexDirection: "column",
                overflowY: "scroll",
                height: "100%"
              }}>
              {this.props.activeWS.items.map((child, index) => {
                let activeReports = [];
                if (tab.activeChildren.length > 0) {
                  activeReports = tab.activeChildren.map(item => {
                    return item.id.split("+")[1];
                  });
                }

                return (
                  <TopLevelNavItem
                    id={child.id}
                    path={child.path}
                    tab={tab}
                    text={child.name}
                    key={index}
                    index={index}
                    parentIndex={tab.activeChildren[0].index[0]}
                    type={child.type}
                    fileType={child.fileType}
                    selectedTab={this.props.selectedTab}
                    selected={activeReports.includes(child.id)}
                    clickHandler={this.clickHandler}
                    removeHandler={this.props.removeHandler}
                  />
                );
              })}
            </div>
          );
        }
      } else if (this.props.activeWS.reports.length > 0) {
        //do something special for tableau
        return (
          <div
            className="parent-top-level-nav-item"
            style={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              height: "100%"
            }}>
            {this.props.activeWS.reports.map((child, index) => {
              let activeReports = tab.activeChildren.map(item => {
                return item.id.split("+")[1];
              });

              return (
                <TopLevelNavItem
                  id={child.id}
                  path={child.path}
                  tab={tab}
                  text={child.name}
                  key={index}
                  index={index}
                  parentIndex={tab.activeChildren[0].index[0]}
                  type={child.type}
                  fileType={child.fileType}
                  selectedTab={this.props.selectedTab}
                  selected={activeReports.includes(child.id)}
                  clickHandler={this.clickHandler}
                  removeHandler={this.props.removeHandler}
                />
              );
            })}
          </div>
        );
      } else {
        return <div></div>;
      }
    }
  }
  renderDropDown() {
    const { tab, activeWS, workspaces } = this.props;

    let size;

    if (tab.children === undefined) {
      let group = {};
      if (tab.workspaceId === activeWS.workspaceId) {
        group = activeWS;
      } else {
        group = workspaces.filter(item => {
          return item.workspaceId === tab.workspaceId;
        });
        group = group[0];
      }

      size = group.children.length;
      //tab.children = group.children;
    } else {
      size = tab.children.length;
    }
    size = 30 * size;
    if (size > 350) {
      size = 350;
    }
    return (
      // <div className="nav-dropdown" style={{ height: size + "px" }}>
      <div className="nav-dropdown">
        <div className="nav-dropdown-header"></div>
        <div style={{ paddingRight: "8px", paddingLeft: "8px" }}>
          <input
            className="input-box"
            placeholder="search"
            ref={input => (this.tabSearch = input)}
            onClick={evt => {
              evt.stopPropagation();
              evt.preventDefault();
            }}
            onChange={event => {
              this.handleInputChange(event, "search");
            }}
            style={{
              position: "relative",
              width: "100%"
            }}
          />
        </div>
        {this.renderChildren()}
      </div>
    );
  }
  render() {
    let icon = null;
    let selected = false;
    if (this.props.tab.icon !== undefined) {
      switch (this.props.tab.icon) {
        case 1:
          icon = (
            <div className="top-level-icon">
              <AppChartAnalytics
                height={"18px"}
                // width={"24px"}
                color={"white"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case 2:
          icon = (
            <div className="top-level-icon">
              <AppChartUser
                height={"18px"}
                // width={"24px"}
                color={"white"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case 3:
          icon = (
            <div className="top-level-icon">
              <AppChartBar
                height={"18px"}
                // width={"24px"}
                color={"white"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case 4:
          icon = (
            <div className="top-level-icon">
              <AppChartLine
                height={"18px"}
                // width={"24px"}
                color={"white"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case 5:
          icon = (
            <div className="top-level-icon">
              <AppChartLineDown
                height={"18px"}
                // width={"24px"}
                color={"white"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case 6:
          icon = (
            <div className="top-level-icon">
              <AppPie
                height={"18px"}
                // width={"24px"}
                color={"white"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case 7:
        case "files":
          icon = (
            <div className="top-level-icon">
              <FolderIcon
                height={"18px"}
                // width={"24px"}
                color={"white"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case 8:
        case "acculift":
          icon = (
            <div className="top-level-icon">
              <LiftModelIcon
                height={"18px"}
                // width={"24px"}
                color={"white"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case 9:
        case "promoOpt":
          icon = (
            <div className="top-level-icon">
              <PromoPlanIcon
                height={"18px"}
                // width={"24px"}
                color={"white"}
                fill={"white"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case 10:
        case "accubase":
          icon = (
            <div className="top-level-icon">
              <BaselineIcon
                height={"18px"}
                // width={"24px"}
                color={"white"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case 11:
        case "mdm":
          icon = (
            <div className="top-level-icon">
              <InputSourceIcon
                height={"18px"}
                // width={"24px"}
                color={"white"}
                fill={"white"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case 12:
        case "powerBI":
        case "tableau":
          icon = (
            <div className="top-level-icon" style={{ marginTop: "-3px" }}>
              <ReportSvg
                height={"16px"}
                // width={"24px"}
                color={"white"}
                fill={"white"}
                // viewBox={"0 -1 24 24"}
              />
            </div>
          );
          break;
        case "powerBIMgmt":
          icon = (
            <div className="top-level-icon" style={{ marginTop: "1px" }}>
              <FolderMgmtIcon
                height={"18px"}
                // width={"24px"}
                color={"white"}
                fill={"white"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case "userManagement":
          icon = (
            <div className="top-level-icon" style={{ marginTop: "1px" }}>
              <ManageUsersIcon
                height={"18px"}
                // width={"24px"}
                color={"white"}
                fill={"white"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        case "reportSubscriptions":
          //TODO change to proper icon
          icon = (
            <div className="top-level-icon" style={{ marginTop: "1px" }}>
              <EnvelopeIcon
                height={"18px"}
                // width={"24px"}
                color={"white"}
                fill={"white"}
                viewBox={"-1 2 24 24"}
              />
            </div>
          );
          break;
        default:
          break;
      }
    }

    if (this.props.tab.activeChildren === null || this.props.tab.children === undefined) {
      if (this.props.selectedTab[0] !== -1) {
        if (this.props.tabs[this.props.selectedTab[0]].id === this.props.tab.id) {
          selected = true;
        }
      }
      return (
        <div
          className={"tab tab-parent " + (selected ? " tab-parent-selected" : "")}
          ref={this.wrapperRef}
          // className={
          //   this.props.selected ? "tab tab-selected" : "tab tab-unselected"
          // }
          onClick={() => {
            this.props.clickHandler(this.props.tab, this.props.tab.index, this.props.tab.type);
          }}
          key={this.props.index}
          // onClick={() => {
          //   this.setState({ openDropDown: !this.state.openDropDown });
          // }}
        >
          {icon !== null ? <div style={{ marginTop: "5px" }}>{icon}</div> : null}
          <div className="parent-nav-item">{this.props.tab.title}</div>
        </div>
      );
    } else {
      let title = this.props.tab.workspaceName;
      if (title === undefined || title === "") {
        title = this.props.tab.title;
      }
      if (this.props.tab.alias !== undefined && this.props.tab.alias !== "") {
        title = this.props.tab.alias;
      }

      selected = false;

      if (this.props.tabs[this.props.selectedTab[0]] !== undefined) {
        if (this.props.tabs[this.props.selectedTab[0]].workspaceId === this.props.tab.workspaceId) {
          selected = true;
        }
      }

      return (
        <div
          className={
            (this.state.openDropDown ? "tab tab-parent tab-parent-open" : "tab tab-parent") +
            (selected ? " tab-parent-selected" : "")
          }
          ref={this.wrapperRef}
          // className={
          //   this.props.selected ? "tab tab-selected" : "tab tab-unselected"
          // }
          // onClick={() =>
          //   this.props.clickHandler(
          //     this.props.tab,
          //     this.props.index,
          //     this.props.tab.type
          //   )
          // }
          key={this.props.index}
          onClick={() => {
            // history.push("/reports/landing/" + this.props.tab.workspaceId);

            this.props.clickHandler(this.props.tab, [this.props.index, -1], "tab");
            //this.setState({ openDropDown: !this.state.openDropDown });
          }}>
          <div className="parent-nav-item">
            {icon !== null ? icon : null}
            {title}
            {this.state.openDropDown ? this.renderDropDown() : null}
          </div>
          <div
            className="dd-chevron"
            style={{ position: "relative", top: "4px", paddingLeft: "2px" }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              this.setState({ openDropDown: !this.state.openDropDown });
            }}>
            <RightChevron
              transform={"rotate(90,12,12)"}
              height={"24px"}
              width={"24px"}
              color={"white"}
              viewBox={"0 -2 24 24"}
            />
          </div>
        </div>
      );
    }
  }
}

TopLevelParentNavItem = onClickOutside(TopLevelParentNavItem);
export default TopLevelParentNavItem;
